/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

try {
 var triggerBttn = document.getElementById( 'trigger-overlay' ),
     overlay = document.querySelector( 'div.overlay' ),
     closeBttn = overlay.querySelector( 'button.overlay-close' );
     transEndEventNames = {
         'WebkitTransition': 'webkitTransitionEnd',
         'MozTransition': 'transitionend',
         'OTransition': 'oTransitionEnd',
         'msTransition': 'MSTransitionEnd',
         'transition': 'transitionend'
     },
     transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
     support = { transitions : Modernizr.csstransitions };

 function toggleOverlay() {
     if( classie.has( overlay, 'open' ) ) {
         classie.remove( overlay, 'open' );
         classie.add( overlay, 'close' );
         var onEndTransitionFn = function( ev ) {
             if( support.transitions ) {
                 if( ev.propertyName !== 'visibility' ) return;
                 this.removeEventListener( transEndEventName, onEndTransitionFn );
             }
             classie.remove( overlay, 'close' );
         };
         if( support.transitions ) {
             overlay.addEventListener( transEndEventName, onEndTransitionFn );
         }
         else {
             onEndTransitionFn();
         }
     }
     else if( !classie.has( overlay, 'close' ) ) {
         classie.add( overlay, 'open' );
     }
 }

triggerBttn.addEventListener( 'click', toggleOverlay );
closeBttn.addEventListener( 'click', toggleOverlay );
} catch (e){}

function displayProfile(profileId) {
    jQuery('[id$=meet-the-team-overlay]').css('display', 'none');
    jQuery('#'+ profileId).css('display', 'block');
}

function contactDetailsColour(colour) {
    jQuery('.contact-details > h3').css('color', colour);
    jQuery('.contact-details > h4').css('color', colour);
}

function getNavWidth() {
    var width = jQuery(window).width();
    var navPercentage;

    if (width >= 1500) {
        navPercentage = "30%";
    } else if (width >= 1000) {
        navPercentage = "45%";
    } else if (width >= 800) {
        navPercentage = "60%";
    } else {
        navPercentage = "100%";
        jQuery('#mySidenav').css('text-align', 'center');
    }

    return navPercentage;
}

 function openNav() {
     var navWidth = getNavWidth();
     document.getElementById("mySidenav").style.width = navWidth;
     jQuery('.burger-icon > img').attr("src","/wp-content/uploads/2017/05/arrow-white.png");
     jQuery('#mySidenav').css('padding-right', "9%");
     contactDetailsColour('black');

     jQuery('.burger-icon').click(function() {
         closeNav();
     });
 }

 function closeNav() {
     document.getElementById("mySidenav").style.width = "0";
     jQuery('#mySidenav').css('padding-right', "0%");
     jQuery('.burger-icon > img').attr("src","/wp-content/uploads/2017/05/burger-icon.png");
     contactDetailsColour('white');

     jQuery('.burger-icon').click(function() {
         openNav();
     });
 }

function initMatchHeight() {
    jQuery('.match-height-footer').matchHeight();
}

function scrollToSection(mainElement) {
    jQuery('html,body').animate({
        scrollTop: jQuery(mainElement).offset().top
    });
}

function initInvestmentManagementSubMenuOnClick(buttonIdArray, arrayIndex) {
    return function() {
        var mainWrapperDiv = '.sub-section-wrapper';
        var sectionTitle = buttonIdArray[arrayIndex][1];
        var sectionId = buttonIdArray[arrayIndex][0];
        var pickledBluewood = '#233747';
        var sectionLinkId = '#' + sectionId + '-li';
        var goldenRod = '#fecd67';
        var whiteArrow = '/wp-content/uploads/2017/05/bullet-point-WHITE.png';
        var blueArrow = '/wp-content/uploads/2017/05/bullet-point-DARK-BLUE.png';
        var yellowArrow = '/wp-content/uploads/2017/05/bullet-point.png';

        jQuery('.sub-section-detailed').hide();

        // set title text
        jQuery('.sub-section-wrapper .book-content-white').html(sectionTitle);

        // set main background colour
        jQuery(mainWrapperDiv).css('background-color', buttonIdArray[arrayIndex][2]);

        // Set link colours
        jQuery('.expanded-sub-menu li').css('color', 'white');

        // white background on dark
        if (buttonIdArray[arrayIndex][2] == pickledBluewood) {
            jQuery(mainWrapperDiv).css('color', 'white');
            jQuery(sectionLinkId).css('color', goldenRod);
        } else {
            jQuery(mainWrapperDiv).css('color', pickledBluewood);
            jQuery(sectionLinkId).css('color', pickledBluewood);


            jQuery('.book-content-white').css('color', 'white');
        }

        jQuery(mainWrapperDiv).fadeIn('slow');
        jQuery('.' + buttonIdArray[arrayIndex][0] + '-detailed').fadeIn();

        // scroll down so the section is at the top of the screen
        scrollToSection(mainWrapperDiv);
    }
}

function initFinancialPlanningSubMenu() {
    var goldenRod = '#fecd67';
    var halfBaked = '#89c9cc';
    var pickledBluewood = '#233747';

    // close button
    jQuery('#sub-section-close-button').click(function () {
        jQuery('.sub-section-wrapper').fadeOut('slow');
    });

    // open button
    var buttonIdArray = [
                            ['wealth-perception', 'Wealth perception', halfBaked],
                            ['knowing-the-risks', 'Knowing the risks', goldenRod],
                            ['building-managing-plan', 'Building and managing your plan', halfBaked],
                            ['three-pot-system', 'Three pot system', pickledBluewood],
                            ['bespoke-services', 'Bespoke services', goldenRod]
                        ];

    // go through all the buttons and assign an on click value
    for (var i = 0; i < buttonIdArray.length; i++) {
        var sectionId = "#more-info-" + buttonIdArray[i][0];
        jQuery( sectionId ).click( initInvestmentManagementSubMenuOnClick(buttonIdArray, i) );
    }
}

function initInvestmentManagementSubMenu() {

    // close button
    jQuery('#sub-section-close-button').click(function () {
        jQuery('.sub-section-wrapper').fadeOut('slow');
    });

    // open button
    var buttonIdArray = [
                            ['why-invest', 'Why Invest?', '#fecd67'],
                            ['investment-beliefs', 'Investment Belief', '#89c9cc'],
                            ['how-we-invest', 'How we invest', '#fecd67'],
                            ['investment-management', 'How we make investment decisions', '#233747']
                        ];

    // go through all the buttons and assign an on click value
    for (var i = 0; i < buttonIdArray.length; i++) {
        var sectionId = "#more-info-" + buttonIdArray[i][0];
        jQuery( sectionId ).click( initInvestmentManagementSubMenuOnClick(buttonIdArray, i) );
    }
}

function initContactLinkMenu() {
    jQuery('#menu-item-176').find('a').attr('data-toggle', 'modal');
    jQuery('#menu-item-176').find('a').attr('data-target', '#ModalEnquireToday');
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery('.burger-icon').click(function() {
            openNav();
        });

        try {
            initMatchHeight();
        } catch(e) {}

        initContactLinkMenu();
      }
  },
  'investment_management': {
    init: function() {
      // JavaScript to be fired on all pages
    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
      initInvestmentManagementSubMenu();
    }
  },
  'financial_planning': {
    init: function() {
      // JavaScript to be fired on all pages
    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
      initFinancialPlanningSubMenu();
    }
  }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
